<script setup lang="ts">
import { cloneDeep } from 'lodash-es'
import { updateDepartmentApi } from '@/api/department'

const props = defineProps(['department'])
const emit = defineEmits(['confirm'])
const open = defineModel<boolean>('open')
const message = useMessage()
const departmentFormState = ref<any>({ deptName: '' })
const departmentFormRef = ref()

watchEffect(() => {
  departmentFormState.value = cloneDeep(props.department)
})

function onSubmit() {
  departmentFormRef.value.validate().then(async () => {
    const { id, deptName } = departmentFormState.value
    const isSuccess = await updateDepartmentApi({ id, deptName })
    if (isSuccess) {
      message.success('修改成功')
      open.value = false
      departmentFormRef.value?.resetFields()
      emit('confirm')
    }
  })
}
</script>

<template>
  <a-modal v-model:open="open" title="修改部门名称" width="50%" :centered="true" @ok="onSubmit">
    <a-form ref="departmentFormRef" :model="departmentFormState" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="部门名称" name="deptName" required>
        <a-input v-model:value="departmentFormState.deptName" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
