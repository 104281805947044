<script setup lang="ts">
import { CarryOutOutlined, EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { createVNode } from 'vue'
import { Button, Modal, Popconfirm, message } from 'ant-design-vue'
import AddDepartmentModal from './components/add-department-modal.vue'
import UpdateDepartmentModal from './components/update-department-modal.vue'
import AddUserModal from './components/add-user-modal.vue'
import { getUserByDept } from '@/api/user'

import { UserTypeMap } from '@/constant/user'
import { deleteDepartmentApi, deleteUsersFromDepartmentApi } from '@/api/department'
import { deepFind } from '@/utils/tree'

const route = useRoute()
const userByDept = ref()
const usersInDept = ref<any[]>([])
const usersNoDept = ref<any[]>([])
const selectedDept = ref()
const checkedKeys = ref<any[]>([])
const addDepartmentModalVisible = ref(false)
const updateDepartmentModalVisible = ref(false)
const addUserModalVisible = ref(false)
const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '用户ID', width: 80, dataIndex: 'id' },
    { title: '用户名', dataIndex: 'loginName' },
    {
      title: '用户类型',
      dataIndex: 'userType',
      customRender({ record }) {
        return UserTypeMap.get(record.userType)
      },
    },
    {
      title: '设备控制权限',
      dataIndex: 'authControl',
      customRender({ record }) {
        return record.authControl ? '有' : '无'
      },
    },
    { title: '操作', customRender({ record }) {
      return h(Popconfirm, { title: '确定移出？', async onConfirm() {
        await deleteUsersFromDepartmentApi([record.id], selectedDept.value.id)
        message.success('成功移出')
        await refresh()
      } }, () => h(Button, { type: 'link', size: 'small', danger: true }, () => '移出部门'))
    } },
  ],
  config: { total: 0, loading: false, isBorder: false, isSerialNo: true, isSelection: true, isOperate: true },
  pagination: false,
})

onMounted(refresh)

async function refresh() {
  userByDept.value = await getUserByDept()
  usersInDept.value = userByDept.value.usersInDept
  usersNoDept.value = userByDept.value.usersNoDept
  if (selectedDept.value) {
    onCheckDepartment(selectedDept.value.id)
  }
  else {
    onCheckDepartment(usersInDept.value[0].id)
  }
}

function onCheckDepartment(deptId: number) {
  const dept: any = deepFind(o => o.id === deptId)(usersInDept.value)

  if (dept) {
    selectedDept.value = dept
    checkedKeys.value = [dept.id]
    state.data = dept.users
  }
}

function handleClick(e: any, node: any) {
  selectedDept.value = node
  switch (e.key) {
    case 'add':
      addDepartmentModalVisible.value = true
      break
    case 'rename':
      updateDepartmentModalVisible.value = true
      break
    case 'delete':
      showDeleteConfirm()
      break
    default:
      break
  }
}

function showDeleteConfirm() {
  Modal.confirm({
    title: '确定要删除该部门?',
    icon: createVNode(ExclamationCircleOutlined),
    okText: '确定',
    okType: 'danger',
    cancelText: '取消',
    async onOk() {
      await deleteDepartmentApi(selectedDept.value.id)
      selectedDept.value = undefined
      await refresh()
    },
  })
}

function onAddUser() {
  addUserModalVisible.value = true
}

function onAddRootDepartment() {
  selectedDept.value = { id: 0 }
  addDepartmentModalVisible.value = true
}
</script>

<template>
  <page-container>
    <content-header :title="route.meta.title" />
    <flex-content :need-padding="false">
      <div h-full flex>
        <div
          w-300px class="of-x-hidden of-y-auto scrollbar" border-solid border-0 border-r-1
          style="background: var(--bg-color);border-color: rgb(230, 230, 230);"
        >
          <div style="border-bottom:1px solid rgb(230, 230, 230);" p-10px>
            <a-button size="small" w-full @click="onAddRootDepartment">
              新增部门
            </a-button>
          </div>
          <a-directory-tree
            v-model:checked-keys="checkedKeys" v-model:selected-keys="checkedKeys" block-node
            :auto-expand-parent="true" :show-icon="true" :show-line="false" :tree-data="usersInDept"
            :field-names="{ children: 'children', title: 'deptName', key: 'id' }" @select="(_keys, e) => onCheckDepartment(e.node.dataRef?.id)"
          >
            <template #icon>
              <CarryOutOutlined />
            </template>
            <template #title="slotData">
              <span flex-1 overflow-hidden class="ellipsis">
                {{ slotData.deptName }}
              </span>
              <!-- <a-input size="small" /> -->
              <a-dropdown flex-none placement="bottom" trigger="click">
                <EllipsisOutlined ml-10px />
                <template #overlay>
                  <a-menu @click="(e) => handleClick(e, slotData.data)">
                    <a-menu-item key="add">
                      新增子部门
                    </a-menu-item>
                    <a-menu-item key="rename">
                      重命名
                    </a-menu-item>
                    <a-menu-item key="delete">
                      删除
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </a-directory-tree>
        </div>
        <div flex-1 flex-col flex>
          <div
            px-12px py-10px flex justify-between
            style="border-bottom:1px solid rgb(230, 230, 230);background: var(--bg-color);"
          >
            <div flex justify-start items-center>
              <span font-600 text-16px>{{ selectedDept?.deptName || '部门名称' }}</span>
            </div>
            <div flex justify-end>
              <a-space>
                <a-button type="primary" @click="onAddUser">
                  新增用户
                </a-button>
              </a-space>
            </div>
          </div>
          <div flex-1 flex overflow-hidden p-10px>
            <table-layout :need-header="false">
              <template #content>
                <base-table v-bind="state" />
              </template>
            </table-layout>
          </div>
        </div>
      </div>
    </flex-content>
    <AddDepartmentModal v-model:open="addDepartmentModalVisible" :parent-id="selectedDept?.id" @confirm="refresh" />
    <UpdateDepartmentModal v-model:open="updateDepartmentModalVisible" :department="selectedDept" @confirm="refresh" />
    <AddUserModal v-model:open="addUserModalVisible" :users="usersNoDept" :department-id="selectedDept?.id" @confirm="refresh" />
  </page-container>
</template>

<style lang="scss" scoped>
:deep(.ant-tree.ant-tree-directory) {

  .ant-tree-treenode {
    display: flex;

    .ant-tree-node-content-wrapper {
      display: flex;
      align-items: center;
      overflow: hidden;

      .ant-tree-title {
        display: flex;
        flex: 1;
        overflow: hidden;
      }
    }
  }

  .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
    // color: #1677ff;
  }

  .ant-tree-treenode-selected::before {
    // background-color: rgb(200 200 200);
  }
}
</style>
