/**
 * 获取所有的部门
 */
export const queryDepartmentsApi = () => useGet('/runtime/department')

/**
 * 添加部门
 */
export const addDepartmentApi = (department: any) => usePost('/runtime/department', department)

/**
 * 修改部门
 */
export const updateDepartmentApi = (department: any) => usePut('/runtime/department', department)

/**
 * 通过id删除部门
 */
export const deleteDepartmentApi = (id: number) => useDelete(`/runtime/department/${id}`)

/**
 * 一个部门添加多个用户
 */
export const addUsersToDepartmentApi = ({ departmentId, userIds }: { departmentId: number, userIds: number[] }) => usePut('/runtime/department/batch-add-users', { dept: departmentId, userIds })

/**
 * 从部门中批量删除用户
 */
export const deleteUsersFromDepartmentApi = (userIds: number[], departmentId: number) => usePut('/runtime/department/batch-remove-users', { userIds, dept: departmentId })
