<script setup lang="ts">
import { addDepartmentApi } from '@/api/department'

const props = defineProps(['parentId'])
const emit = defineEmits(['confirm'])
const open = defineModel<boolean>('open')
const message = useMessage()
const departmentFormState = ref<any>({ deptName: '' })
const departmentFormRef = ref()

function onSubmit() {
  departmentFormRef.value.validate().then(async () => {
    departmentFormState.value.parentId = props.parentId
    const isSuccess = await addDepartmentApi(departmentFormState.value)
    if (isSuccess) {
      message.success('修改成功')
      open.value = false
      departmentFormRef.value?.resetFields()
      emit('confirm')
    }
  })
}
</script>

<template>
  <a-modal v-model:open="open" title="新增部门" width="50%" :centered="true" @ok="onSubmit">
    <a-form ref="departmentFormRef" :model="departmentFormState" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="部门名称" name="deptName" required>
        <a-input v-model:value="departmentFormState.deptName" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
